import { theme } from '@maersktankersdigital/web-components'
import styled from 'styled-components/macro'
import { StyledRouterLink } from '~components/atoms/router-link/styles'
import { StyledHeading } from '~components/atoms/typography/heading/heading.styles'
import { StyledParagraph } from '~components/atoms/typography/paragraph/paragraph.styles'
import { StyledTagFilter } from '~components/layout/tag-filter/tag-filter.styles'
import Wrapper from '~components/layout/wrapper/wrapper'
import { StyledWrapper } from '~components/layout/wrapper/wrapper.styles'
import { StyledAutoCompleteInputWrapper } from '~components/molecules/auto-complete-input/auto-complete-input.styles'
import { StyledPoolPointsTableResult } from '~pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-result/pool-points-result.styles'
import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'
import { StyledPageHeader } from './vessel-header/vessel-header.styles'

export const StyledVesselPage = styled.div`
  background: ${Colors.white};
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  ${StyledPageHeader} {
    > ${StyledWrapper} {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 30px;
    }

    button {
      align-self: flex-start;
      white-space: nowrap;

      @media only screen and (min-width: 1600px) {
        align-self: center;
      }
    }
  }

  ${StyledTagFilter} {
    margin-bottom: 40px;
  }

  table {
    border-collapse: collapse;
    text-indent: 0;
    border-color: inherit;
  }
`

export const StyledVesselPageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // For preventing the inner wrapper of going out of the flexbox/auto sizing next to the navigation
  min-width: 0;

  ${StyledWrapper} {
    width: 100%;
    padding-left: 52px;
    padding-right: 52px;

    @media only screen and (min-width: 1900px) {
      padding-left: 152px;
      padding-right: 152px;
    }
  }
`

export const StyledVesselPageSubHeaderInner = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${StyledAutoCompleteInputWrapper} {
    width: 366px;
    margin-bottom: ${theme.SPACINGS[4]};

    > div {
      width: 100%;
    }

    input {
      height: 40px;
      border-radius: 4px;
      border: 1px solid ${theme.COLORS.primary.maerskBlue};
      box-shadow: none;
      text-transform: none;
      font-size: ${theme.FONTS.sizes.sm};

      &::placeholder {
        color: ${theme.COLORS.greys.mid};
      }
    }
  }
`

export const StyledVesselPageSubHeaderHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${theme.SPACINGS[4]};
  border-bottom: 1px solid ${Colors.greys.lightest};

  ${StyledHeading} {
    position: relative;
    padding-right: 20px;
    margin-right: ${theme.SPACINGS[2]};

    &:after {
      content: '';
      position: absolute;
      background: ${Colors.greys.lightest};
      width: 1px;
      height: 23px;
      right: 0;
      top: calc(50% + 3px);
      transform: translateY(-50%);
    }
  }

  ${StyledParagraph} {
    margin-top: 5px;
    text-transform: uppercase;

    &:before {
      content: '';
      position: relative;
      display: inline-block;
      margin-right: ${theme.SPACINGS[1]};
      background: ${Colors.oranges.base};
      width: ${theme.SPACINGS[1]};
      height: ${theme.SPACINGS[1]};
      border-radius: 50%;
    }
  }
`

export const StyledVesselPageSubHeader = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  ${StyledVesselPageSubHeaderInner} {
    ${StyledVesselPageSubHeaderHeadingWrapper} {
      ${StyledHeading} {
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    ${StyledHeading} {
      padding-bottom: ${theme.SPACINGS[4]};
      border-bottom: 1px solid ${Colors.greys.lightest};
    }
  }
`

export const StyledVesselPageSubHeaderAdditionalInfo = styled.div`
  align-self: flex-start;
  margin-left: 64px;
  max-width: 465px;
  min-width: 300px;
  width: 30%;

  ${StyledPoolPointsTableResult} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 15px;
    margin-top: 20px;
    border-top: 1px solid ${theme.COLORS.greys.light};

    p {
      &:first-child {
        margin-right: 10px;
      }
    }
  }

  ${StyledParagraph} {
    line-height: 1.5;
  }
`

export const StyledVesselPageSubHeaderAdditionalInfoInner = styled.div`
  background-color: ${Colors.blues.lighter};
  padding: 32px;
  margin-top: 32px;
`

export const StyledVesselPageSubHeaderAdditionalInfoLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.SPACINGS[2]};

  ${StyledRouterLink} {
    color: ${theme.COLORS.primary.maerskBlue};

    a {
      display: flex;
      align-items: center;

      &:not(:first-child) {
        margin-top: ${theme.SPACINGS[1]};
      }
    }
  }
`
