import {
  ArrowUpIcon,
  Checkbox,
  MoveColumnsIcon,
  theme,
  typographyStyles,
} from '@maersktankersdigital/web-components'
import { pageHeaderHeights } from 'src/constants/constants'
import styled, { css } from 'styled-components/macro'
import { Divider } from '~pages/pages-behind-login/position-list/position-list.styles'
import { easeDecelerate } from '~theme/deprecated-vt/deprecated-vt-styles/transitions/eases'
import { Z_INDEX } from '~types/z-index'

type IconProps = {
  $isActive: boolean
}

export const TableWrapper = styled.div`
  transition-property: max-height;
  transition-duration: 200ms;
  transition-timing-function: ${easeDecelerate};
  will-change: max-height;
  overflow-x: auto;
  overflow-y: hidden;
`

export const TableCell = styled.td<{
  $width?: number
  isLast?: boolean
}>`
  ${typographyStyles.paragraph2};
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-right: ${(p) => (p.isLast ? 0 : theme.SPACINGS[2])};
  min-width: ${(p) => p.$width && `${p.$width}px`};
  max-width: ${(p) => p.$width && `${p.$width}px`};

  :first-of-type {
    padding-left: 12px;
  }
`

export const VesselNameTableCell = styled(TableCell)`
  position: sticky;
  left: 0;
  z-index: 1;
  padding-right: 0;
  width: 320px;
`

export const ExpandButtonCell = styled.td<{ disabled?: boolean }>`
  color: ${(p) => (p.disabled ? theme.COLORS.greys.mid : 'initial')};
  pointer-events: ${(p) => (p.disabled ? 'none' : 'initial')};
`

export const TableRow = styled.tr<{
  $isCommercialManagement?: boolean
  $isDuplicated?: boolean
}>`
  --background-color: ${({ $isDuplicated, $isCommercialManagement }) =>
    $isDuplicated
      ? theme.COLORS.greys.light
      : $isCommercialManagement && $isDuplicated
        ? theme.COLORS.greys.light
        : $isCommercialManagement && !$isDuplicated
          ? theme.COLORS.primary.white
          : theme.COLORS.greys.ultraLight};
  --stroke-color: ${({ $isDuplicated }) =>
    $isDuplicated ? theme.COLORS.greys.mid : theme.COLORS.greys.light};

  height: 56px;
  background-color: var(--background-color);

  ${VesselNameTableCell} {
    background-color: var(--background-color);
  }

  ${({ $isCommercialManagement }) =>
    $isCommercialManagement &&
    css`
      outline: 1px solid var(--stroke-color);
      & td:first-of-type {
        border-left: 1px solid var(--stroke-color);
        background-color: var(--background-color);
      }
      & td:last-of-type {
        border-right: 1px solid var(--stroke-color);
      }
    `}
`

export const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 4px;
  table-layout: fixed;
  width: 100%;
  min-width: 100%;
`

export const TableHeaderCell = styled.th.attrs({ scope: 'col' })<{
  $isExporting?: boolean
  $width?: number
  isDragging?: boolean
  sortable?: boolean
}>`
  ${typographyStyles.label}
  text-align: left;
  padding: 0;
  cursor: ${(props) => (props.sortable ? 'pointer' : 'default')};
  white-space: wrap;
  background-color: ${(props) =>
    props.isDragging ? theme.COLORS.greys.light : theme.COLORS.primary.white};
  width: ${(p) => p.$width && `${p.$width}px`};
  max-width: ${(p) => p.$width && `${p.$width}px`};
  min-width: ${(p) => p.$width && `${p.$width}px`};

  :first-of-type {
    --width: ${({ $isExporting, $width }) =>
      $width && $isExporting ? `${$width - 33}px` : `${$width}px`};
    padding-left: 12px;
    cursor: pointer;
    position: sticky;
    left: 0;
    max-width: var(--width);
    width: var(--width);
    min-width: var(--width);
    z-index: 1;
  }

  & svg {
    flex-shrink: 0;
  }
`

export const DraggableHeaderCell = styled(TableHeaderCell)`
  cursor: move;
`

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const StyledTableHead = styled.thead`
  overflow: auto;
`

export const TableRowHeader = styled(TableRow)`
  position: relative;
  background-color: ${theme.COLORS.primary.white};
`

export const StyledCheckbox = styled(Checkbox)`
  margin: 0;
`

export const CheckboxHeaderWrapper = styled.div`
  height: 56px;
  width: 33px;
  background-color: ${theme.COLORS.primary.white};
  display: flex;
  justify-content: center;
  padding-left: 12px;
  align-items: center;
  position: sticky;
  left: 0;
  z-index: 1;
`

export const StyledArrowUpIcon = styled(ArrowUpIcon)<IconProps>`
  display: inline-block;
  transition: transform 0.3s ease-out;
  will-change: transform;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.2)};
`

export const StyledMoveColumnIcon = styled(MoveColumnsIcon)`
  margin-right: 2px;
`

export const ShowCurrentVoyageButtonWrapper = styled.div`
  cursor: pointer;
  display: grid;
  place-items: center;
  height: 56px;
  padding-right: 8px;
  padding-left: 8px;
`

export const ExpandableContent = styled.div`
  overflow: hidden;
  transition-property: max-height;
  transition-duration: 200ms;
  transition-timing-function: ${easeDecelerate};
  will-change: max-height;
`

export const AreaTitle = styled.span`
  ${typographyStyles.label};
`

export const AreaTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const AreaWrapper = styled.div`
  margin-bottom: ${theme.SPACINGS[5]};
`

export const AreaHeader = styled.div<{
  level?: string
}>`
  & ${AreaTitle} {
    ${({ level }) => level === 'level1' && typographyStyles.headline4}
    color: ${({ level }) => level === 'level1' && theme.COLORS.greys.dark};
  }

  display: flex;
  align-items: center;
  cursor: ${({ level }) => (level === 'level1' ? 'default' : 'pointer')};
  justify-content: space-between;
  height: 40px;
  background-color: ${({ level }) =>
    level === 'level1'
      ? theme.COLORS.primary.white
      : level === 'level2'
        ? theme.COLORS.secondary.lightBlue.tint
        : theme.COLORS.secondary.lightBlue.primary};
  padding: 0 12px;
  margin-bottom: ${theme.SPACINGS[1]};
`

export const CheckboxAreaWrapper = styled.div`
  background-color: transparent;
`

export const StyledDivider = styled(Divider)`
  ::before {
    background-color: ${theme.COLORS.greys.mid};
    margin-top: ${theme.SPACINGS[0]};
    min-width: 100%;
  }
`

export const VoyageTableRow = styled.tr`
  background-color: ${theme.COLORS.primary.white};
`

export const StickyHeaderWrapper = styled.div`
  position: sticky;
  top: ${pageHeaderHeights.mobile};
  z-index: ${Z_INDEX.PageHeader};
  background-color: ${theme.COLORS.primary.white};

  @media only screen and (${theme.BREAKPOINTS.sm}) {
    top: ${pageHeaderHeights.desktop};
  }
`

export const TableCellCheckbox = styled(TableCell)`
  padding: 0;
  min-width: 22px;
  max-width: 22px;
`
