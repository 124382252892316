import { sortItems } from '~components/navigation-and-footer/navigation/top-navigation/utils'
import { useGetPools } from './queries/pools/use-get-pools'

export function usePoolSelectOptions() {
  const { data } = useGetPools()
  const options = !data
    ? []
    : data.map(({ name }) => ({
        label: name,
        value: name,
      }))
  return sortItems(options, (option) => option.label)
}
