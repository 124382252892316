import { graphql } from './generated'

export const getMTOpenVessels = graphql(/* GraphQL */ `
  query getMTOpenVessels {
    getMTOpenVessels {
      openVessels {
        exposureArea
        fromDate
        Maersk {
          cargoGrade
          ID
          loadingAreaETA
          localLoadingAreaETA
          localOpenDate
          openDate
          openPort
          openRegion
          openWorldArea
          pool
          vesselId
          vesselName
        }
      }
      totalCount
      latestCreatedDate
      totalExposure {
        exposureArea
        vessels {
          cargoGrade
          ID
          loadingAreaETA
          localLoadingAreaETA
          localOpenDate
          openDate
          openPort
          openRegion
          openWorldArea
          pool
          vesselId
          vesselName
        }
      }
    }
  }
`)

export const getTotalExposure = graphql(/* GraphQL */ `
  query GetMTOpenVessels2($pool: String!, $cargoGrade: String) {
    getTotalExposure(pool: $pool, cargoGrade: $cargoGrade) {
      totalExposure {
        exposureArea
        vessels {
          cargoGrade
          ID
          loadingAreaETA
          localLoadingAreaETA
          localOpenDate
          openDate
          openPort
          openRegion
          openWorldArea
          pool
          vesselId
          vesselName
          voyageNumber
        }
      }
    }
  }
`)
