export enum PoolName {
  City = 'City',
  Intermediate = 'Intermediate',
  Chemicals1 = 'Chemicals 1',
  Chemicals2 = 'Chemicals 2',
  Chemicals3 = 'Chemicals 3',
  Chemicals4 = 'Chemicals 4',
  Flexi = 'Flexi',
  Handy = 'Handy',
  Panamax = 'Panamax',
  LR1 = 'LR1',
  LR2 = 'LR2',
  MR = 'MR',
  Afra = 'Afra',
  Aframax = 'Aframax',
  Suezmax = 'Suezmax',
}
