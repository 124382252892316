// src/mocks/handlers.js
import { HttpResponse, delay, graphql, http } from 'msw'
import { apiBaseV1, apiBaseV2 } from '../utils/base-url'
import { mockComments } from './mock-data/comments'
import { mockMarket } from './mock-data/market'
import {
  mockEarningsMonthly,
  mockEarningsQuarterly,
  mockEarningsYearly,
} from './mock-data/vessels-earnings'

import { mockExposure } from './mock-data/exposure'

export const handlers = [
  // http.get(`${apiBaseV2()}/dashboard/summary`, () => {
  //   return HttpResponse.json(dashboardSummaryMockData, { status: 200 })
  // }),
  http.get(`${apiBaseV2()}/pools/:poolId/comments`, () => {
    return HttpResponse.json(mockComments, { status: 200 })
  }),
  http.get(`${apiBaseV1()}/pool/:poolId/market`, () => {
    return HttpResponse.json(mockMarket, { status: 200 })
  }),
  // http.get(`${apiBaseV2()}/forecast/calculated?poolId=:poolId`, () => {
  //   return HttpResponse.json(mockForecast, { status: 200 })
  // }),
  http.get(
    `${apiBaseV2()}/pools/:poolId/vessels-earnings`,
    async ({ request }) => {
      const url = new URL(request.url)
      const aggregate = url.searchParams.get('aggregate')

      if (aggregate === 'quarterly') {
        return HttpResponse.json(mockEarningsQuarterly, { status: 200 })
      }

      if (aggregate === 'yearly') {
        return HttpResponse.json(mockEarningsYearly, { status: 200 })
      }
      await delay(2000)
      return HttpResponse.json(mockEarningsMonthly, { status: 200 })
    },
  ),
  graphql.query('getMTOpenVessels', () => {
    return HttpResponse.json(mockExposure)
  }),
  // graphql.query('GetMTOpenVessels2', () => {
  //   return HttpResponse.json(mockTotalExposure)
  // }),
]
