import { PoolName } from '~constants/pools'

const poolsOrder: PoolName[] = [
  PoolName.City,
  PoolName.Intermediate,
  PoolName.Chemicals1,
  PoolName.Chemicals2,
  PoolName.Chemicals3,
  PoolName.Chemicals4,
  PoolName.Flexi,
  PoolName.Handy,
  PoolName.MR,
  PoolName.Panamax,
  PoolName.LR1,
  PoolName.LR2,
  PoolName.Afra,
  PoolName.Aframax,
  PoolName.Suezmax,
]

export function sortItems<T>(
  items: T[],
  keyExtractor: (item: T) => string,
): T[] {
  return items.sort((a, b) => {
    const indexA = poolsOrder
      .map((pool) => pool.toLowerCase())
      .indexOf(keyExtractor(a).toLowerCase())
    const indexB = poolsOrder
      .map((pool) => pool.toLowerCase())
      .indexOf(keyExtractor(b).toLowerCase())

    if (indexA === -1) return 1 // a is not in poolsOrder, it should be at the end
    if (indexB === -1) return -1 // b is not in poolsOrder, it should be at the end

    return indexA - indexB // sort according to poolsOrder
  })
}
