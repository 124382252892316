import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { Modal } from '~components/molecules/modal/modal'
import { localStorageItemKeys } from '~constants/local-storage-items'
import { cookieBannerV2, getFeatureToggle } from '~utils/feature-toggles'
import { CookieDeclaration } from './sub-components/cookie-declaration'
import { CookieSettings } from './sub-components/cookie-settings'

export enum Cookie {
  necessary = 'strictly necessary',
  statistical = 'statistical',
}

export function CookieBannerV2() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContentPage, setModalContentPage] = useState<'1' | '2'>('1')

  useEffect(() => {
    if (!getFeatureToggle(cookieBannerV2)) {
      return
    }

    const storedCookies = localStorage.getItem(
      localStorageItemKeys.retainedPostLogout.allowedCookies,
    )

    if (!storedCookies) setIsModalOpen(true)
  }, [])

  if (!getFeatureToggle(cookieBannerV2)) {
    return null
  }

  return (
    <Box
      sx={{ zIndex: 10001 }}
      component={Modal}
      open={isModalOpen}
      title={
        modalContentPage === '1'
          ? 'This website uses cookies'
          : 'Cookie declaration'
      }
    >
      <>
        {modalContentPage === '1' && (
          <CookieSettings
            onClickHandler={() => setModalContentPage('2')}
            closeModal={() => setIsModalOpen(false)}
          />
        )}
        {modalContentPage === '2' && (
          <CookieDeclaration
            onClickHandler={() => setModalContentPage('1')}
            closeModal={() => setIsModalOpen(false)}
          />
        )}
      </>
    </Box>
  )
}
