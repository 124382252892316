import { TopBarProvider } from '@maersktankersdigital/web-components'
import { useEffect } from 'react'
import StoreProvider from 'src/context/store-provider'
import UserProvider from 'src/context/user-provider'
import { StyledToastContainer } from '~components/atoms/toasts/styled-toast-container'
import { FeedbackIframe } from '~pages/open-pages/feedback'
import { ReportBugIframe } from '~pages/open-pages/report-bug'
import { PageRoute } from '~routing/constants/page-route'
import { App } from './app'

export function VtRoutesWrapper() {
  const host = window.location.host

  useEffect(() => {
    const setFavicon = () => {
      const favicon = document.getElementById('favicon') as HTMLAnchorElement

      if (host.includes('localhost') || host.includes('test')) {
        favicon.href = '/favicon-test.svg'
      }
      if (host.includes('stage')) {
        favicon.href = '/favicon-stage.svg'
      }
    }
    setFavicon()
  }, [host])

  if (location.pathname === PageRoute.FEEDBACK) {
    return <FeedbackIframe />
  }

  if (location.pathname === PageRoute.REPORT_BUG) {
    return <ReportBugIframe />
  }

  return (
    <>
      <StyledToastContainer
        position="top-center"
        autoClose={false}
        newestOnTop
      />

      <UserProvider>
        <StoreProvider>
          <TopBarProvider>
            <App />
          </TopBarProvider>
        </StoreProvider>
      </UserProvider>
    </>
  )
}
