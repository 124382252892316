import { Box } from '@mui/material'
import { ApiRoutes } from '~api/routes'
import AutoCompleteInput from '~components/molecules/auto-complete-input/auto-complete-input'
import { IVesselRow } from '~components/organisms/tables/vessel/vessel-table'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { useGetMeVT } from '~hooks/queries/me/use-get-me-vt'
import { useApi } from '~hooks/use-api'
import { FavoritesSelect } from '~pages/pages-behind-login/onboarded-vessel/ops-dashboard-page/components/favourites-select'
import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'

const getSearchableOptions = (vessels: IVesselRow[]) =>
  vessels.map((vessel) => ({
    searchableItems: [
      { value: vessel.vesselId, isHidden: true },
      { value: vessel.imoNumber, color: Colors.greys.dark },
      { value: vessel.vesselName },
    ],
  }))

export function AutoCompleteAndFavorites() {
  const { data: vessels } = useApi<IVesselRow[]>(ApiRoutes.Vessels.all)
  const { data: me } = useGetMeVT()

  const isMtUser = useHasScope(ExactScopeName.maerskTankersUser)
  const hasFavoriteVessels =
    me?.favoriteVesselsNames && !!me.favoriteVesselsNames.length

  if (!hasFavoriteVessels && !isMtUser) return null

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        my: 3,
      }}
    >
      {vessels && isMtUser && (
        <AutoCompleteInput
          id="vesselName"
          label="Switch to another vessel"
          placeholder="Search for a specific Vessel"
          options={getSearchableOptions(vessels)}
        />
      )}
      <FavoritesSelect />
    </Box>
  )
}
