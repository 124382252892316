import { theme } from '@maersktankersdigital/web-components'
import { Box, Typography } from '@mui/material'

export function PortEssentialDisclaimer() {
  return (
    <Box
      sx={{
        backgroundColor: theme.COLORS.secondary.lightBlue.tint,
        p: 5,
        maxWidth: '400px',
      }}
    >
      <Typography variant="paragraph1">
        The Port Essentials module is currently based solely on Q88 data. Always
        verify Owner’s pool points and trading restrictions, as well as the
        “Check before you fix” policy prior to fixing.
      </Typography>
    </Box>
  )
}
