import { TextButton } from '@maersktankersdigital/web-components'
import { useEffect, useState } from 'react'
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import { ApiClient, ApiRoutes } from '~api'
import { apiGetAction } from '~api/actions'
import { IUsersResponse } from '~api/users/all'
import { IVesselsErrorResponse } from '~api/vessels/error'
import { IVesselManager } from '~api/vessels/read'
import { CheckboxGroupField } from '~components/forms/checkbox-group-field/checkbox-group-field'
import DatePickerField from '~components/forms/date-picker-field/date-picker-field'
import { SelectField } from '~components/forms/select-field/select-field'
import { AnimatedAlert } from '~components/molecules/animated-alert/animated-alert'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import ConfirmModal from '~components/molecules/modal/confirm-modal/confirm-modal'
import {
  ModalFooter,
  PrimaryButton,
} from '~components/molecules/modal/modal.styles'
import { PartnerManagerGuard } from '~components/organisms/guards/partner-manager-guard'
import {
  ContentWrapper,
  ModalSecondFooter,
  StyledImoInput,
} from '~components/organisms/modals/vessel/editvesselmodal.styles'
import { usePoolSelectOptions } from '~hooks/use-pool-select-options'
import { TrackFieldValue } from '~pages/pages-behind-login/vessels-page/vessels-page-popup/vessels-page-popup.config'
import { VesselStatus } from '~types/vessel.types'
import { generatePathName } from '~utils/generate-path-name'
import { gtm } from '~utils/gtm'

interface ISelect {
  label: string
  value: string
}

export interface IVesselData {
  archivedReason?: string
  imoNumber: string
  manager?: IVesselManager
  partnerManager?: string
  pool?: string
  status?: string
  targetDate?: string
  track?: string
  vesselId: string
  vesselName?: string
  voyageManager?: string
}

interface IEditVesselModalContent {
  vessel?: IVesselData
}

const EditVesselModalContent = ({ vessel }: IEditVesselModalContent) => {
  const pools = usePoolSelectOptions()
  const isCommercialManagementVessel =
    vessel?.status === 'commercial-management'

  const [managerOptions, setManagerOptions] = useState<ISelect[]>([])
  const [partnerManagerOptions, setPartnerManagerOptions] = useState<ISelect[]>(
    [],
  )

  useEffect(() => {
    apiGetAction<IUsersResponse[]>(ApiRoutes.Vessels.managers).then(
      (managers) => {
        setManagerOptions(
          managers.map((manager) => {
            const { sub: value, name: label } = manager
            return { value, label }
          }),
        )
      },
    )
    apiGetAction<IUsersResponse[]>(ApiRoutes.Users.Managers.all).then(
      (managers) => {
        setPartnerManagerOptions(
          managers.map((manager) => {
            const { sub: value, name: label } = manager
            return { value, label }
          }),
        )
      },
    )
  }, [])

  return managerOptions.length && partnerManagerOptions.length ? (
    <ContentWrapper>
      <StyledImoInput
        label="IMO NUMBER"
        name="imo"
        value={vessel?.imoNumber ?? ''}
      />
      <div>
        <SelectField
          label="POOL"
          name="pool"
          placeholder="Select Pool..."
          options={pools}
          defaultValue={vessel?.pool ?? ''}
        />
      </div>
      <DatePickerField
        name="targetDate"
        label="Target date"
        selected={vessel?.targetDate ? new Date(vessel.targetDate) : new Date()}
      />
      <div>
        <SelectField
          label="VOYAGE MANAGER"
          name="voyageManager"
          placeholder="Select manager..."
          options={managerOptions}
          defaultValue={vessel?.voyageManager ?? ''}
        />
      </div>
      <div>
        <SelectField
          label="MT PIC"
          name="partnerManager"
          placeholder="Select PIC..."
          options={partnerManagerOptions}
          defaultValue={vessel?.partnerManager ?? ''}
        />
      </div>
      <div>
        <CheckboxGroupField
          label={
            isCommercialManagementVessel
              ? 'Change to a Pool Vessel'
              : 'Change to a commercial management vessel '
          }
          name="status"
          options={[
            {
              value: `${
                isCommercialManagementVessel
                  ? 'changeToPool'
                  : 'changeToCommercial'
              }`,
              label: 'Yes',
            },
          ]}
        />
      </div>
    </ContentWrapper>
  ) : null
}

interface IVesselModal {
  close?: () => void
  vesselData?: IVesselData
}

export const EditVesselModal = ({ close, vesselData }: IVesselModal) => {
  const { vesselId } = useParams()
  const [isMarkOnboardingConfirmed, setIsMarkOnboardingConfirmed] =
    useState(false)
  const [isMarkOnboardedConfirmed, setIsMarkOnboardedConfirmed] =
    useState(false)
  const [isMarkArchivedConfirmed, setIsMarkArchivedConfirmed] = useState(false)
  const methods = useForm({ mode: 'onChange' })
  const { setStatus } = useAnimatedAlert()

  const onSubmitUpdate: SubmitHandler<FieldValues> = async (fields) => {
    const status = fields?.status?.includes('changeToPool')
      ? TrackFieldValue.ONBOARDING
      : fields?.status?.includes('changeToCommercial')
        ? TrackFieldValue.COMMERCIAL_MANAGEMENT
        : null

    const payload = {
      pool: fields.pool,
      poolPartner: fields.poolPartner,
      ...(fields.targetDate && {
        targetDate: fields.targetDate,
      }),
      ...(fields.voyageManager && {
        voyageManager: fields.voyageManager,
      }),
      ...(fields.partnerManager && {
        partnerManager: fields.partnerManager,
      }),
      ...(!!status && {
        status,
      }),
    }

    await ApiClient.Vessels.update(
      {
        body: payload,
      },
      {
        vesselId,
      },
    ).then((response) => {
      if ((response as IVesselsErrorResponse).error) {
        setStatus('Vessel update failed', 5000, 'error')
      } else {
        mutate(generatePathName(ApiRoutes.Vessels.read, { vesselId }))
        setStatus('Vessel successfully updated')
        close?.()
      }
    })
  }

  const updateStatus = async () => {
    if (
      !isMarkOnboardingConfirmed &&
      !isMarkOnboardedConfirmed &&
      !isMarkArchivedConfirmed
    ) {
      return
    }
    await ApiClient.Vessels.status(
      {
        body: {
          status: isMarkOnboardedConfirmed
            ? VesselStatus.ONBOARDED
            : isMarkOnboardingConfirmed
              ? VesselStatus.ONBOARDING
              : isMarkArchivedConfirmed
                ? VesselStatus.ARCHIVED
                : 'invalid',
        },
      },
      {
        vesselId,
      },
    ).then((response) => {
      if ((response as IVesselsErrorResponse).error) {
        setStatus('Status change failed', 5000, 'error')
      } else {
        if (isMarkOnboardedConfirmed) {
          gtm.pushEvent('internal_click', {
            click_type: 'vessel_onboarded',
          })
        }
        if (isMarkArchivedConfirmed) {
          gtm.pushEvent('internal_click', {
            click_type: 'vessel_archived',
          })
        }

        mutate(generatePathName(ApiRoutes.Vessels.read, { vesselId }))
        setStatus('Status successfully changed')
        close?.()
      }
    })
  }

  const handleConfirm = () => {
    updateStatus()
  }

  const save = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    methods.handleSubmit(onSubmitUpdate)()
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={(e) => save(e)}>
          <EditVesselModalContent vessel={vesselData} />
          <ModalFooter>
            <TextButton type="button" onClick={close}>
              Cancel
            </TextButton>
            <PrimaryButton type="submit">Save</PrimaryButton>
          </ModalFooter>
          <ModalSecondFooter>
            <PartnerManagerGuard>
              {vesselData?.status === VesselStatus.ONBOARDING && (
                <TextButton
                  onClick={() => setIsMarkOnboardedConfirmed(true)}
                  type="button"
                >
                  Mark as onboarded
                </TextButton>
              )}
              {vesselData?.status === VesselStatus.INCOMING && (
                <TextButton
                  onClick={() => setIsMarkOnboardingConfirmed(true)}
                  type="button"
                >
                  Start onboarding
                </TextButton>
              )}
              <TextButton
                variant="warning"
                type="button"
                onClick={() => setIsMarkArchivedConfirmed(true)}
              >
                Archive vessel
              </TextButton>
            </PartnerManagerGuard>
          </ModalSecondFooter>
        </form>
      </FormProvider>
      <ConfirmModal
        content="The onboarding will start and only administrators and users with a role connected to vessel will be able to change data."
        isVisible={isMarkOnboardingConfirmed}
        onConfirm={handleConfirm}
        onClose={() => setIsMarkOnboardingConfirmed(false)}
      />
      <ConfirmModal
        content="The onboarding will finish and only administrators and users with a role connected to vessel will be able to change data."
        isVisible={isMarkOnboardedConfirmed}
        onConfirm={handleConfirm}
        onClose={() => setIsMarkOnboardedConfirmed(false)}
      />
      <ConfirmModal
        content="The vessel will be archived and taken out of the pool."
        isVisible={isMarkArchivedConfirmed}
        onConfirm={handleConfirm}
        onClose={() => setIsMarkArchivedConfirmed(false)}
      />
      <AnimatedAlert />
    </>
  )
}
