import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { MenuItemI } from './primary'

const adminPanelUrlMap = {
  test: 'https://test.admin.insitedigital.net',
  stage: 'https://stage.admin.insitedigital.net',
  production: 'https://admin.insitedigital.net',
}

const adminScopeRoutes: MenuItemI[] = [
  {
    label: 'admin',
    link: constructLinkToAdminPanel(),
  },
  {
    label: 'open vps',
    link: 'http://performance.vpsolutions.dk',
  },
]

function constructLinkToAdminPanel() {
  const origin = window.location.origin

  if (origin.includes('test') || origin.includes('localhost')) {
    return adminPanelUrlMap.test
  }

  if (origin.includes('stage')) {
    return adminPanelUrlMap.stage
  }

  return adminPanelUrlMap.production
}

export function useSecondaryNavLinks(): MenuItemI[] {
  const isInternalUser = useHasScope(ExactScopeName.maerskTankersUser)

  if (isInternalUser) {
    return adminScopeRoutes
  }
  return []
}
