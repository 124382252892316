import {
  Header,
  TopBar,
  useBreakpoint,
} from '@maersktankersdigital/web-components'
import { AppBar } from '@mui/material'
import { useMatches } from 'react-router-dom'
import { SideBarButtons } from '~components/molecules/side-bar-buttons/side-bar-buttons'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { useGetVessels } from '~hooks/queries/vessels/use-get-vessels'
import { useConstructLinkWithPool } from '~hooks/use-construct-link-with-pool'
import { useNavAndFooterConstants } from '~hooks/use-nav-and-footer-constants'
import { PageRoute } from '~routing/constants/page-route'
import { dashboardEnabled, getFeatureToggle } from '~utils/feature-toggles'
import { useDynamicTitle } from './hooks/use-dynamic-title'
import { useSubheaderLinks } from './hooks/use-subheader-links'

export const isV1page = true

export function TopNavigation() {
  const { isMobileSize } = useBreakpoint()
  const { topNavigationHeight } = useNavAndFooterConstants()
  const matches = useMatches()
  const subheaderLinks = useSubheaderLinks()
  const routeWithLeftNavigation = matches.some(
    // @ts-ignore
    (x) => x.handle && !x.handle.disableLeftNavigation,
  )

  const isTechnicalManager = useHasScope(ExactScopeName.technicalManager)
  const isMtUser = useHasScope(ExactScopeName.maerskTankersUser)
  const isAccountManager = useHasScope(ExactScopeName.accountManager)
  const isSuperUser = useHasScope(ExactScopeName.superUser)

  const isDashboardReady = getFeatureToggle(dashboardEnabled)
  const dashboardV2Link = useConstructLinkWithPool('/panel/dashboard/:pool')

  const myFleetTitle = useDynamicTitle(
    'my fleet',
    PageRoute.VESSELS,
    useGetVessels,
  )

  const topBarHeaders: Header[] = [
    ...(isTechnicalManager
      ? []
      : [
          {
            title: 'dashboard',
            link: isDashboardReady
              ? dashboardV2Link
              : `${window.location.origin}/panel/dashboard`,
            isExternal: false,
          },
        ]),
    {
      title: 'financials',
      link: 'panel/financials',
    },
    { title: myFleetTitle, link: '/vessels' },
    ...(!isMtUser
      ? []
      : [{ title: 'position list', link: PageRoute.POSITION_LIST }]),
    ...(!(isAccountManager || isSuperUser)
      ? []
      : [
          {
            title: 'users',
            link: PageRoute.USERS,
          },
        ]),
  ]

  return (
    <>
      <AppBar
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          height: topNavigationHeight,
          '& a': {
            textDecoration: 'none',
          },
        }}
      >
        <TopBar
          headers={topBarHeaders}
          subHeaders={subheaderLinks}
          isHamburgerMenu={isMobileSize && routeWithLeftNavigation}
        />
      </AppBar>
      <SideBarButtons isVisible />
    </>
  )
}
